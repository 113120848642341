import { useEffect, useState, useRef } from "react";
import React from 'react';
import Videos from "./components/Videos";
import "./css/App.css";
import banner from './assets/channels4_banner.jpg';
import Logo from './assets/logo.png';

import { LockClosedIcon } from '@heroicons/react/20/solid'
import { Helmet } from 'react-helmet-async';
//import VideoPlayer from './components/VideoPlayer';

function VideoView(props) {
    const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: 'https://goarosa.net/view/160',
      type: 'video/mp4',
      spriteThumbnails: {
        url: 'https://goarosa.net/v1/preview/160'
      }
    }]
  };

  return (
    <>
      <Helmet>
          <title>{props.title}</title>
          <link href="https://vjs.zencdn.net/7.11.4/video-js.css" rel="stylesheet"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta property="og:image" content="https://goarosa.net/thumbnails/main.png"/>
          <meta property="og:site_name" content="GOA HUB"/>
          <meta property="og:title" content={"GOA HUB" + props.title}/>
          <meta property="og:description" content={props.title + " 영상 다시보기"}/>
          <meta property="og:type" content="video.other"/>
          <meta property="og:image" content={"https://goarosa.net/v1/thumbnails/" + props.index}/>
          <meta property="og:image:secure_url" content={"https://goarosa.net/v1/thumbnails/" + props.index}/>
          <meta property="og:image:type" content="image/png"/>
          <meta property="og:image:width" content="1280"/>
          <meta property="og:image:height" content="720"/>
          <meta property="og:url" content={"https://goarosa.net/view/thumbnails/" + props.index}/>
          <meta property="og:video" content={"https://goarosa.net/view/thumbnails/" + props.index}/>
          <meta property="og:video:url" content={"https://goarosa.net/view/thumbnails/" + props.index}/>
          <meta property="og:video:secure_url" content={"https://goarosa.net/view/thumbnails/" + props.index}/>
          <meta property="og:video:type" content="video/mp4"/>
          <meta property="og:video:width" content="1280"/>
              <meta property="og:video:height" content="720" />
              <script src="https://vjs.zencdn.net/7.11.4/video.min.js"></script>
              <script src="https://unpkg.com/videojs-sprite-thumbnails@0.5.3/dist/videojs-sprite-thumbnails.js"></script>
      </Helmet>
      <div className="pt-[38px] sm:ml-64">
        <div className="rounded-lg">
          <div className="relative mx-auto px-4 sm:px-6 md:px-8 pb-16">
            <main>
              <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 py-4">
                <div className="grid-rows-1">
                  <div>
                  </div>
                </div>
                <div className="pt-[190px] grid-rows-2">
                <div>
                                      <video id="videojs-sprite-thumbnails-player" className="video-js vjs-default-skin"
                                          data-setup='{"aspectRatio":"12:5"}' controls>
                                          <source src="http://goarosa.net/view/160" type='video/mp4'/>
  </video>
                  </div>
                </div>
                <div className="pt-[180px] grid-rows-3">
                  <footer className="p-4 bg-[#121212] rounded-lg shadow md:px-6 md:py-8 dark:bg-[#121212]">
                    <ul className="pt-4 mt-4 pb-4 space-y-2 border-t border-[#262626] dark:border-[#262626]" />
                    <span className=" text-center block text-sm text-[#868686] sm:text-center dark:text-[#868686]">
                      <a href="https://goarosa.net/" className="hover:underline">
                        GOAHUB™
                      </a>
                      . All Rights Reserved.
                    </span>
                    <p className="mt-8 view-text text-center text-base">
                      Async • Kamunari • Acs
                    </p>
                  </footer>
                </div>
              </div>
            </main>
          </div>
              </div>
              <script>
                  {
                      `var player = videojs('videojs-sprite-thumbnails-player');
                      player.spriteThumbnails({
                        interval:60,
                        url: 'https://goarosa.net/v1/preview/160',
                        width: 160,
                        height: 90
                      });
`}
              </script>
      </div>
    </>
  );
}

export default VideoView;
