import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './css/index.css';
import App from './App';
import Banner from './Banner';
import NavMenu from './components/NavMenu';
import Btn from './components/Button';
import Login from './Login';
import VideoView from './VideoView';
import BackGround from './components/Background';
import reportWebVitals from './reportWebVitals';
import logo from './assets/logo.png';
import 'flowbite';

const Main = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    return (
        <>
            <button
                data-drawer-target="separator-sidebar"
                data-drawer-toggle="separator-sidebar"
                aria-controls="separator-sidebar"
                type="button"
                className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
                <span className="sr-only">sidebar</span>
                <svg className="text-[#868686] w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <aside id="separator-sidebar" className="fixed top-0 z-40 w-[14.375rem] h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto bg-[#121212] dark:bg-[#121212]">
                    <ul>
                        <NavMenu />
                    </ul>
                    <ul>
                        <form action="/search" method="get">
                            <div className="flex items-center pb-[21px]">
                                <label htmlFor="simple-search" className="sr-only">Search</label>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" className="w-5 h-5 text-[#868686] dark:text-[#868686]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                    </div>
                                    <input
                                        name="q"
                                        type="text"
                                        id="simple-search"
                                        className="bg-[#1A1A1B] text-gray-900 text-sm rounded-[5px] block w-full pl-10 p-2.5  dark:bg-[#1A1A1B] dark:placeholder-[#868686] dark:text-[#868686]"
                                        placeholder="Search"
                                        required
                                    />
                                </div>
                            </div>
                        </form>
                    </ul>
                    <ul>
                        <Btn index="/1" value="Video" />
                        <Btn index="/4" value="Audio" />
                        <Btn index="/2" value="Sub" />
                        <Btn index="/3" value="Clip" />
                    </ul>
                    <ul className="pt-4 ">
                        <ul className="pt-4 mt-4 space-y-2 border-t border-[#262626] dark:border-[#262626]">
                            <Btn index="login" value="Master Login" />
                        </ul>
                    </ul>
                </div>
            </aside>
            <Routes>
                <Route path="/" element={<App state={1} />} />
                <Route path="/1" element={<App state={1} />} />
                <Route path="/2" element={<App state={2} />} />
                <Route path="/3" element={<App state={3} />} />
                <Route path="/search" element={<App state={100} keyword={searchParams.get('q')} />} />
                <Route path="/4" element={<App state={4} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/watch" element={<VideoView index={searchParams.get('index')} title={searchParams.get('title')}/>} />
            </Routes>
        </>
    )
}

export default Main;