import { useEffect, useState } from "react";
import Videos from "./components/Videos";
import Audios from "./components/Audios";
import "./css/App.css";
import banner from './assets/channels4_banner.jpg';

function App(state) {
  const [videoList, setVideoList] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (state.state != 100) {
      const requestOptions = {
      method: 'GET',
      "Content-Type": 'application/x-www-form-urlencoded'
      };
      fetch(`https://goarosa.net/getFileList?type=${state.state}`, requestOptions)
      .then(response => response.json())
      .then(result => setVideoList(result["list"]))
      .catch(error => console.log('error'.error));
    }
    else {
      const requestOptions = {
      method: 'GET',
      "Content-Type": 'application/x-www-form-urlencoded'
      };
      fetch(`https://goarosa.net/searchFile?q=${state.keyword}`, requestOptions)
      .then(response => response.json())
      .then(result => setVideoList(result["list"]))
      .catch(error => console.log('error'.error));
    }
  }, []);

  const onClick = () => {
    const requestOptions = {
    method: "GET",
    "Content-Type": "application/x-www-form-urlencoded",
    };
    fetch(`https://goarosa.net/searchFile?q=${search}`, requestOptions)
    .then((response) => response.json())
    .then((result) => setVideoList(result["list"]))
    .catch((error) => console.log("error".error));
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
    console.log(search);
    onClick();
    }
  };

  const handleOnChange = (e) => {
    setSearch(e.target.value);
  };

  const getComponent = (type) => {
    if (state.state != 4) {
      return <Videos list={videoList} />
    }
    else
    {
      return <Audios list={videoList} />
    }
  }

  return (
    <div >
      <img
        src={banner}
        className="w-full h-auto"
      />
        <div className="pt-[38px] sm:ml-64">
          <div className="rounded-lg">
            <div className="relative mx-auto px-4 sm:px-6 md:px-8 pb-16">
            <main>
            <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 py-4">
              <div className="grid-rows-1">
                <div>
                </div>
              </div>
              <div className="grid-rows-2">
                {
                  state.state === 4
                  ? <Audios list={videoList} />
                  : <Videos list={videoList} />
                }
              </div>
              <div className="grid-rows-3">
                <footer className="p-4 bg-[#121212] rounded-lg shadow md:px-6 md:py-8 dark:bg-[#121212]">
                <ul className="pt-4 mt-4 pb-4 space-y-2 border-t border-[#262626] dark:border-[#262626]"/>
                <span className=" text-center block text-sm text-[##868686] sm:text-center dark:text-[#868686]">
                <a href="https://goarosa.net/" className="hover:underline">
                GOAHUB™
                </a>
                . All Rights Reserved.
                </span>
                <p className="mt-8 view-text text-center text-base">
                Async • Kamunari • Acs
                </p>
                </footer>
              </div>
            </div>
            </main>
            </div>
          </div>
        </div>
    </div>      
  );
}

export default App;
